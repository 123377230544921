import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../navbar/navbar.service';
import { featureFlag } from '../../../../environments/environment';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  messageTh = '';
  messageEn = '';

  constructor(private navbar: NavbarService) {
    this.navbar.setActiveSidebar(false);
  }

  ngOnInit(): void {
    console.warn('Before Clear - maintenance');
    localStorage.clear();
    this.messageTh = featureFlag.maintenance_message_th;
    this.messageEn = featureFlag.maintenance_message_en;
  }
}
