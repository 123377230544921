import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-memo-plane-ticket',
  templateUrl: './memo-plane-ticket.component.html',
  styleUrls: ['./memo-plane-ticket.component.scss'],
})
export class MemoPlaneTicketComponent implements OnInit, OnChanges {
  tooltipCreatorName = '';
  maxLevelArr: any;
  loa = [];

  @Input() data: any;
  @Input() memo: any;

  @Output() submitted = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.maxLevelArr = Array(this.memo.max_level)
      .fill(0)
      .map((x, i) => i);
    if (this.data) {
      this.data = _.orderBy(this.data, 'sequence', 'desc');
    }
  }

  checkTooltip(index) {
    let tooltip = '';
    this.data[index].members.map((member) => {
      if (member.date_of_approve) {
        const approveAt = `, On ${moment(
          member.date_of_approve,
        ).format('DD/MM/YYYY hh:mm')}`;
        tooltip = tooltip.concat(
          `\n ${member.full_name}${approveAt}`,
        );
      } else {
        tooltip = tooltip.concat(`\n ${member.full_name}`);
      }
    });
    return tooltip;
  }

  ngOnChanges() {
    if (!_.has(this.memo, 'send_status')) {
      this.memo.send_status = true;
    }
    if (this.data) {
      this.data = _.orderBy(this.data, 'sequence', 'desc');
    }
  }
}
