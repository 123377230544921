import { Component, Input } from '@angular/core';
import { PasswordPolicy } from '../../../modules/setting/component/theme/component/password-policy-edit/password-policy.model';

@Component({
  selector: 'app-password-rule-box',
  templateUrl: './password-rule-box.component.html',
  styleUrls: ['./password-rule-box.component.scss'],
})
export class PasswordRuleBoxComponent {
  @Input() passwordPolicy: PasswordPolicy;
  @Input() checkLowerCase: boolean;
  @Input() checkUpperCase: boolean;
  @Input() checkNumber: boolean;
  @Input() checkSymbols: boolean;
  @Input() checkLength: boolean;

  constructor() {}
}
