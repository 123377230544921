import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../navbar/navbar.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  hasPermission = false;
  recall = false;
  message = this.hasPermission
    ? `Sorry, but you don't have permission to access this page.`
    : `Sorry, we're unable to find the page you're looking for.`;
  memoType;
  isEvidence;

  constructor(
    private activateRoute: ActivatedRoute,
    private navbar: NavbarService,
  ) {
    this.navbar.setActiveSidebar(false);
    this.activateRoute.queryParams.subscribe((res) => {
      this.hasPermission = !!res.permission;
      this.memoType = res.memoType;
      this.recall = JSON.parse(res.recall);
      this.isEvidence = JSON.parse(res.isEvidence);
    });
  }

  get memoTypeEN(): string {
    return this.memoType === 'contract' ? 'Contract' : 'Memo';
  }

  get memoTypeTH(): string {
    return this.memoType === 'สัญญา' ? 'Contract' : 'เอกสาร';
  }
}
