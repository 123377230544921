<div class="font-charmonman">&#8203;</div>
<div class="position-relative px-5 pb-4">
  <div class="modal-header d-flex justify-content-between">
    <h4>
      {{ 'PROFILE.Setting Signature with Typing' | translate }}
    </h4>
    <svg-icon src="assets/images/icons/closeX.svg"
        [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
        class="pointer"
        (click)="onCancelClick()"></svg-icon>
  </div>
  <div class="row pt-4">
    <div class="col-12"
        [class.col-md-6]="!onlyFullName">
      <div class="form-group mb-4">
        <label class="d-block">{{ 'PROFILE.Full Name' | translate }}</label>
        <input class="form-control"
            [readOnly]="selectOnly"
            [(ngModel)]="_fullName"
            (ngModelChange)="onFullNameChange($event, fullNameCanvas)">
      </div>
      <div class="canvas-group mb-4">
        <label class="d-block col-12 px-0"
            for="canvas-fullname">
          {{ 'PROFILE.Full Name Example' | translate }}
        </label>
        <canvas #fullNameCanvas
            class="canvas-signature col-12"
            id="canvas-fullname"
            height="140"
            [class.selected]="selectedSignature === 'full'"
            (click)="onTextSignatureSelect('full')">
        </canvas>
      </div>
    </div>
    <div *ngIf="!onlyFullName"
        class="col-12 col-md-6">
      <div class="form-group mb-4">
        <label class="d-block">{{ 'PROFILE.Initials' | translate }}</label>
        <input class="form-control"
            [readOnly]="selectOnly"
            [(ngModel)]="_abbreviatedName"
            (ngModelChange)="redrawTextSignature($event, abbreviatedNameCanvas)">
      </div>
      <div class="canvas-group mb-4">
        <label class="d-block px-0 col-12"
            for="canvas-abbreviated-name">
          {{ 'PROFILE.Initials Example' | translate }}
        </label>
        <canvas #abbreviatedNameCanvas
            class="canvas-signature col-12"
            id="canvas-abbreviated-name"
            height="140"
            [class.selected]="selectedSignature === 'abbreviate'"
            (click)="onTextSignatureSelect('abbreviate')">
        </canvas>
      </div>
    </div>
  </div>
  <div class="row justify-content-end px-2">
    <button class="btn btn-outline"
        [style.--c]="themeList?.header_table_color"
        (click)="onCancelClick()">
      {{ 'MEMOS.CANCEL' | translate }}
    </button>
    <button appSetTheme
        type="button"
        class="btn btn-confirm mx-2"
        (click)="onConfirmClick()">
      {{ 'MEMOS.CONFIRM' | translate }}
    </button>

  </div>
</div>