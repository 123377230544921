import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Directive({
  selector: '[appCustomModalHeader]',
})
export class CustomModalHeaderDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
@Directive({
  selector: '[appCustomModalBody]',
})
export class CustomModalBodyDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
@Directive({
  selector: '[appCustomModalFooter]',
})
export class CustomModalFooterDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
})
export class ModalConfirmationComponent {
  @ContentChild(CustomModalHeaderDirective)
  customHeader: CustomModalHeaderDirective;

  @ContentChild(CustomModalBodyDirective)
  customBody: CustomModalBodyDirective;

  @ContentChild(CustomModalFooterDirective)
  customFooter: CustomModalFooterDirective;

  subscription: Subscription[] = [];
  themeList: ThemeList;
  @Input() title: string;
  @Input() body: string;
  @Input() btnCancel: string;
  @Input() btnConfirm: string;
  @Input() laddaLoading: boolean;
  @Input() disableBtn: boolean;
  @Input() validate = false;
  @Input() hasFooter = true;

  @Output() closeModal = new EventEmitter();
  @Output() confirmModal = new EventEmitter();

  constructor(
    private themeService: ThemeService,
    private modalService: NgbModal,
  ) {
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  close(): void {
    // I have to emit closeModal event before dismiss modal. Otherwise
    //   target component will not received closeModal event.
    try {
      this.closeModal.emit();
    } catch (e) {}
    this.modalService.dismissAll();
  }

  submit(): void {
    this.confirmModal.emit();
  }
}
