import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CanvasWhiteboardComponent,
  CanvasWhiteboardOptions,
} from 'ng2-canvas-whiteboard';

@Component({
  selector: 'app-signature',
  viewProviders: [CanvasWhiteboardComponent],
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent {
  @Output() saveImage = new EventEmitter();
  @ViewChild('canvasWhiteboard', { static: false })
  canvasWhiteboard: CanvasWhiteboardComponent;
  showColorPicker = false;
  selectedColor = '';
  canvasOptions: CanvasWhiteboardOptions = {
    drawButtonEnabled: true,
    drawButtonClass: 'drawButtonClass',
    drawButtonText: 'Draw',
    clearButtonEnabled: true,
    clearButtonClass: 'clearButtonClass',
    clearButtonText: 'Clear',
    undoButtonText: 'Undo',
    undoButtonEnabled: true,
    redoButtonText: 'Redo',
    redoButtonEnabled: true,
    colorPickerEnabled: true,
    fillColorPickerText: 'Fill',
    strokeColorPickerText: 'Stroke',
    saveDataButtonEnabled: true,
    saveDataButtonText: 'Save draw',
    lineWidth: 3,
    strokeColor: 'rgb(0,0,0)',
    shouldDownloadDrawing: false,
  };
  signatureColorList = [];

  constructor() {}

  onCanvasUndo() {}

  onSave(event) {
    this.canvasWhiteboard.generateCanvasBlob(() => {
      this.saveImage.emit(event);
    });
    // this.canvasWhiteboard.generateCanvasData((flie) => {
    // });
    // this.onSaveImage.emit(event);
  }
}
