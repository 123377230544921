import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationPasswordExpired } from '../../models/notification';

@Component({
  selector: 'app-password-expired-modal',
  templateUrl: './password-expired-modal.component.html',
  styleUrls: ['./password-expired-modal.component.scss'],
})
export class PasswordExpiredModalComponent {
  @Input() data!: NotificationPasswordExpired;

  constructor(public activeModal: NgbActiveModal) {}

  close(): void {
    this.activeModal.close();
  }
}
