import {
  ShowLoaderAction,
  HideLoaderAction,
} from './../../store/loader/load.actioins';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerSubject: BehaviorSubject<boolean>;
  public spinner: Observable<boolean>;

  constructor(
    private ngxSpinner: NgxSpinnerService,
    private store: Store,
  ) {
    this.spinnerSubject = new BehaviorSubject(false);
    this.spinner = this.spinnerSubject;
  }

  show(): void {
    this.store.dispatch(new ShowLoaderAction());
    this.ngxSpinner.show();
  }

  hide(): void {
    this.store.dispatch(new HideLoaderAction());
    this.ngxSpinner.hide();
  }
  setSpinner(active: boolean): void {
    this.spinnerSubject.next(active);
  }

  showSpinner(active?: boolean, name = '', conf?: SpinnerConf): void {
    active ? this.ngxSpinner.show() : this.ngxSpinner.hide();
  }

  getSpinner(): Observable<boolean> {
    return this.spinner;
  }
}

export interface SpinnerConf {
  type: string;
  size: 'small' | 'medium' | 'large' | 'default';
  bdColor: 'rgba(0, 0, 0, 1)';
  color: string;
  template: string;
}
