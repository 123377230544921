import { Component, Input, OnInit } from '@angular/core';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-credit-warning-box',
  templateUrl: './credit-warning-box.component.html',
  styleUrls: ['./credit-warning-box.component.scss'],
})
export class CreditWarningBoxComponent implements OnInit {
  @Input() document;
  @Input() person;
  @Input() style: 'left' | 'right' = 'left';

  warningMessages = [];
  isContract: boolean;

  subscription: Subscription[] = [];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.isContract = this.document.memo_type === 'contract';
    this.setUpWarningBox();
    this.subscription.push(
      this.translate.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.setUpWarningBox();
        },
      ),
    );
  }

  setUpWarningBox() {
    this.warningMessages = [];
    if (!this.document.can_approve) {
      return;
    }
    if (
      !this.isContract &&
      this.document.enable_ddoc &&
      this.person &&
      !this.person.otp_enable
    ) {
      this.warningMessages.push({
        type: 'otp-enable',
      });
    }
    if (this.isContract) {
      if (!this.document.credit_avail.contract) {
        this.warningMessages.push({
          type: 'contract-credit',
          message: this.translate.instant(
            'APPROVAL.CONTRACT-CREDIT-WARNING',
          ),
        });
      }
      if (
        this.document.otp_enable &&
        !this.document.credit_avail.sms
      ) {
        this.warningMessages.push({
          type: 'sms-credit',
          message: this.translate.instant(
            'APPROVAL.SMS-CREDIT-WARNING',
          ),
        });
      }
    }
  }

  closeWarningBox(warning) {
    this.warningMessages = this.warningMessages.filter(
      (obj) => obj.type !== warning.type,
    );
  }
}
