import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMentionHighlight]',
})
export class MentionHighlightDirective {
  // with regex u flag , \u{0E01}-\u{0E5B} stand for thai languages in unicode format , it mean ก-๙
  readonly mentionPattern =
    /(?:@\[)(\d+)(?::)([\u{0E01}-\u{0E5B}a-zA-Z-._!"`'#%&,:;<>=@{}()\d\s]+\s*[\u{0E01}-\u{0E5B}a-zA-Z-._!"`'#%&,:;<>=@{}()\d\s]+)(?:\])/gmu;

  mentionNodeIndex: number;

  constructor(private el: ElementRef) {}

  @HostListener('input') onInput(): void {
    this.processInnerHTML(this.el.nativeElement.innerHTML);
  }

  setNodeIndex(childNodes: NodeListOf<ChildNode>): void {
    for (let index = 0; index < childNodes.length; index++) {
      const childNode = childNodes.item(index).nodeValue;

      if (childNode?.match(this.mentionPattern)?.length) {
        this.mentionNodeIndex = this.mentionPattern.exec(childNode)
          .index
          ? index + 1
          : index;

        break;
      }
    }
  }

  setCaretPosition(element: HTMLElement): void {
    const range = document.createRange();
    const selection = window.getSelection();

    if (element.childNodes.length > 0) {
      range.setStartAfter(element.childNodes[this.mentionNodeIndex]);
    } else {
      range.setStartAfter(element);
    }
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    range.detach();
  }

  repalceMention(_mention: string, id: number, name: string): string {
    return `<span data-id="${id}" style="color: #3294c1;" contenteditable="false">@${name}</span><br>`;
  }

  processInnerHTML(innerHTML: string): void {
    if (innerHTML.match(this.mentionPattern)?.length) {
      this.setNodeIndex(this.el.nativeElement.childNodes);
      this.setInnerHTML(
        innerHTML.replace(this.mentionPattern, this.repalceMention),
      );
    }
  }

  private setInnerHTML(innerHTML: string) {
    this.el.nativeElement.innerHTML = innerHTML;
    this.setCaretPosition(this.el.nativeElement);
  }
}
