import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';

export interface PickedDateRange {
  from: string;
  to: string;
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnChanges {
  @Input() fromDate: NgbDateStruct = null;
  @Input() toDate: NgbDateStruct = null;
  @Output() fromDateChange = new EventEmitter<NgbDateStruct>();
  @Output() toDateChange = new EventEmitter<NgbDateStruct>();

  @Output() selected = new EventEmitter<PickedDateRange>();
  @Input() isClearDate: boolean;
  @Output() isClearDateChange = new EventEmitter();

  @ViewChild('datepicker') datepicker: NgbInputDatepicker;

  formattedFromDate: string;
  formattedToDate: string;
  hoveredDate: NgbDateStruct = null;

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes.isClearDate) {
        this.clearDate();
      }
    }, 0);
  }

  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.fromDateChange.emit(date);
      this.formattedFromDate = this.setFormatDate(this.fromDate, '-');
    } else if (
      this.fromDate &&
      !this.toDate &&
      date.equals(this.fromDate)
    ) {
      this.toDate = this.fromDate;
      this.toDateChange.emit(this.fromDate);
      this.formattedToDate = this.setFormatDate(this.toDate, '-');
      this.onSelected();
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.toDateChange.emit(date);
      this.formattedToDate = this.setFormatDate(this.toDate, '-');
      this.onSelected();
    } else {
      this.toDate = null;
      this.toDateChange.emit(null);
      this.fromDate = date;
      this.fromDateChange.emit(date);
      this.formattedFromDate = this.setFormatDate(this.fromDate, '-');
    }
  }

  onSelected(): void {
    this.datepicker?.close();
    this.selected.emit({
      from: this.formattedFromDate,
      to: this.formattedToDate,
    });
  }

  setFormatDate(
    date: NgbDateStruct,
    str: string,
    reverse = false,
  ): string {
    return reverse
      ? date.day + str + date.month + str + date.year
      : date.year + str + date.month + str + date.day;
  }

  isHovered(date: NgbDate): boolean {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return (
      this.toDate &&
      date.after(this.fromDate) &&
      date.before(this.toDate)
    );
  }

  isRange(date: NgbDate): boolean {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  clearDate() {
    this.formattedFromDate = '';
    this.formattedToDate = '';
    this.fromDate = null;
    this.toDate = null;
    this.hoveredDate = null;
    this.onSelected();
  }

  get rangeDate(): string {
    if (!this.fromDate) {
      return '';
    }

    if (!this.toDate) {
      return this.setFormatDate(this.fromDate, '/', true);
    }

    return (
      this.setFormatDate(this.fromDate, '/', true) +
      '  -  ' +
      this.setFormatDate(this.toDate, '/', true)
    );
  }
}
