import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-permission-not-found',
  templateUrl: './permission-not-found.component.html',
  styleUrls: ['./permission-not-found.component.scss'],
})
export class PermissionNotFoundComponent {
  @Input() isAckDetail = false;
  constructor() {}
}
