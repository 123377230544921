import { IdpNodeInfo } from './ndid.model';

export class SetCitizenId {
  static readonly type = 'SetCitizenId';

  constructor(public citizenId: string) {}
}

export class SetIAL {
  static readonly type = 'SetIAL';

  constructor(public ial: number) {}
}

export class SetSelectedIdpNodeId {
  static readonly type = 'SetSelectedIdpNodeId';

  constructor(public selectedIdpNode: IdpNodeInfo) {}
}
