<div *ngIf="warningMessages.length"
    class="position-alert"
    [ngClass]="style">
  <div class="credit-warning-box"
      [ngClass]="style">
    <div>
      <svg-icon src="assets/images/icons/red-noti.svg"
          [svgStyle]="{ 'width.px':40, 'height.px':40 }"></svg-icon>
    </div>
    <div>
      <div class="box-warning"
          *ngFor="let warning of warningMessages">
        <ng-container *ngIf="warning.type !== 'otp-enable'; else OTPEnableWarning">
          {{ warning.message }}
        </ng-container>
        <i (click)="closeWarningBox(warning)"
            class="fal fa-times icon-close"></i>
      </div>
      <ng-template #OTPEnableWarning>
        <div>
          {{ 'CERTIFICATE.OTP-NOT-ENABLE'|translate }}
          <div [routerLink]="['/', 'login']">
            <span class="go-to-otp-setting">{{'CERTIFICATE.GOTO-OTP-SETTINGS'|translate}}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>