import {
  AfterViewInit,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Directive({
  selector: '[appContentEditable]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContentEditableDirective),
      multi: true,
    },
  ],
})
export class ContentEditableDirective
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @Input() preventEnter = false;

  /*
   * MutationObserver IE11 fallback (as opposed to input event for modern browsers).
   * When mutation removes a tag, i.e. delete is pressed on the last remaining character
   * inside a tag — callback is triggered before the DOM is actually changed, therefore
   * setTimeout is used
   */
  private observer = new MutationObserver(() => {
    setTimeout(() => {
      // To write innerHTML value into control
      this.onChange(
        ContentEditableDirective.processValue(
          this.elementRef.nativeElement.innerHTML,
        ),
      );
    });
  });

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    // Enable contenteditable
    this.setContentEditable(true);
  }

  /*
   * null and other falsy control values are treated as empty string to
   * prevent IE11 outputting 'null', also single <br> is replaced with empty
   * string when passed to the control
   */
  private static processValue(value: string | null): string {
    const processed = value || '';

    return processed.trim() === '<br>' ? '' : processed;
  }

  private onTouched = () => {
    // Callback that marks control as touched and allows FormHooks use
  };

  private onChange: (value: string) => void = () => {
    // Callback that writes value to control and allows FormHooks use
  };

  private setContentEditable(status: boolean) {
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'contenteditable',
      String(status),
    );
  }

  private preventEnterKey(key: KeyboardEvent) {
    if (key.code === 'Enter' && !key.shiftKey) {
      key.preventDefault();
    }
  }

  ngAfterViewInit(): void {
    // To support IE11 MutationObserver is used to monitor changes to the content
    this.observer.observe(this.elementRef.nativeElement, {
      characterData: true,
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy(): void {
    // Disconnect MutationObserver IE11 fallback on destroy
    this.observer.disconnect();
  }

  // Listen to blur event to mark control as touched
  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  // Reacts to external change
  writeValue(value: string | null): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      ContentEditableDirective.processValue(value),
    );
  }

  // Registers onChange callback
  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  // Registers onTouch callback
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  // Sets disabled state by setting contenteditable attribute to true/false
  setDisabledState(disabled: boolean): void {
    this.setContentEditable(!disabled);
  }

  // Prevent Enter key
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (this.preventEnter) {
      this.preventEnterKey(event);
    }
  }
}
