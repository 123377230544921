<ngx-spinner bdColor="rgb(0 0 0 / 59%)"
    type="square-jelly-box">
  <span class="text-white">Loading...</span>
</ngx-spinner>
<!-- main app container -->
<ng-container *ngIf="loadingSuccess$ | async">
  <div class="container-fluid"
      *ngIf="!navbarActive">
    <router-outlet></router-outlet>
  </div>
  <app-sidebar *ngIf="navbarActive">
    <router-outlet></router-outlet>
    <svg-icon src="assets/images/icons/sign-saved.svg"
        class="d-none"></svg-icon>
    <svg-icon src="assets/images/icons/sign-new.svg"
        class="d-none"></svg-icon>
    <svg-icon src="assets/images/icons/sign-upload.svg"
        class="d-none"></svg-icon>
  </app-sidebar>
</ng-container>

<ng-template #sessionTimoutModal>
  <div class="modal-header red">
    <h4 class="modal-title">
      {{"SESSION-TIMEOUT.Session Timeout" | translate}}
    </h4>
  </div>
  <div class="modal-body text-center">
    <p class="mb-0">{{"SESSION-TIMEOUT.You're being timed out due to inactivity." | translate}}</p>
    <p class="mb-0">{{"SESSION-TIMEOUT.Please choose to stay signed in or to logout." | translate}}</p>
    <p class="mb-0">{{"SESSION-TIMEOUT.Otherwise, you will logged out automatically" | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary"
        (click)="logout()">
      {{"SESSION-TIMEOUT.Logout"|translate}}
    </button>
    <button class="btn btn-primary"
        (click)="dismissAll()">
      {{"SESSION-TIMEOUT.Stay Logged In"|translate}} ({{countdown}})
    </button>
  </div>
</ng-template>