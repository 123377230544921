<input name="datepicker"
    readonly
    class="form-control input-lg w-100"
    ngbDatepicker
    autocomplete="off"
    container="body"
    #datepicker="ngbDatepicker"
    [autoClose]="'outside'"
    [value]="rangeDate"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [dayTemplate]="t"
    placeholder="{{'MEMOS.SELECT' | translate}}"
    (click)="datepicker.toggle()"
    [startDate]="fromDate!">
<ng-template #t
    let-date
    let-focused="focused">
  <span class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
<div>
  <span class="span-calendar">
    <i class="fa fa-calendar i-calendar"
        aria-hidden="true"
        ngbDatepicker
        (click)="datepicker.toggle()"></i>
  </span>
  <div class="icon-x d-inline"
      *ngIf="fromDate || toDate">
    <i class="fa fa-times f-12 fa-sm"
        [ngStyle]="{'font-weight':'400','color':'#999999'}"
        (click)="clearDate()"></i>
  </div>
</div>
