import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import * as _ from 'lodash';
import {
  DropdownResponse,
  DropdownService,
} from '../../service/dropdown.service';
import { AlertService } from '../../../core/services/alert.service';
import { Store } from '@ngxs/store';
import { People } from '../../../modules/memos/model/dropdown.model';

export interface MentionProfile {
  id: number;
  full_name: string;
}

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  @ViewChild('messageInput')
  messageInput: ElementRef;

  photo = localStorage.getItem('photoUrl');
  newComment = '';
  fileUpload: File;
  profileList = [];
  debouncer = new Subject<string>();
  alreadySearchText = [''];
  userList = [];
  userDict = {};
  isMentionOpen = false;
  isNonUser = false;

  mentionConfig = {
    triggerChar: '@',
    labelKey: 'label',
    maxItems: 10,
    allowSpace: true,
    dropUp: true,
    mentionSelect: (mention: People): string => {
      return `@[${mention.value}:${mention.label}]`;
    },
  };

  @Input() memoId: number;
  @Input() key = null;
  @Output() submitted = new EventEmitter();
  @Output() uploaded = new EventEmitter();

  constructor(
    private alert: AlertService,
    private swal: SweetAlertService,
    private translate: TranslateService,
    private dropdownService: DropdownService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.isNonUser = this.store.selectSnapshot<boolean>(
      (state) => state.auth.is_custom,
    );
    if (!this.isNonUser) {
      this.getUserList();
    }
  }

  mentionOpened(): void {
    this.isMentionOpen = true;
  }

  mentionClosed(): void {
    this.isMentionOpen = false;
  }

  handleEnterKey(event: Event): void {
    if (!this.isMentionOpen) {
      event.preventDefault();
      this.addComment();
    }
  }

  addComment(): void {
    let comment;
    if (this.isNonUser) {
      comment = [{ newComment: this.newComment }];
    } else {
      const mentionedPerson = [];
      const mentions = this.messageInput.nativeElement.children;

      Array.from(mentions).forEach((mention: HTMLElement) => {
        const mentionID = mention.getAttribute('data-id');

        if (mentionID && !mentionedPerson.includes(mentionID)) {
          mentionedPerson.push(+mentionID);
        }
      });

      comment = [
        {
          newComment: this.newComment,
          mentionedPerson,
        },
      ];
    }
    this.submitted.emit(comment);
    setTimeout(() => {
      this.newComment = '';
    }, 600);
  }

  getUserList(): void {
    if (this.key) {
      this.getUserListWithKey();
      return;
    }
    const params = {
      type: 'people_for_mention',
      memo_id: this.memoId.toString(),
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: any) => {
        this.profileList = dropdown.people_for_mention;
      },
      (error: { error: string }) => {
        if (this.translate.currentLang === 'th') {
          this.swal.toastNotification({
            type: 'error',
            content: 'ไม่สามารถโหลดข้อมูล user สำหรับการกล่าวถึงได้',
          });
        } else {
          this.swal.toastNotification({
            type: 'error',
            content: `Can't get mention users.`,
          });
        }
      },
    );
  }

  getUserListWithKey(): void {
    const header = { Authorization: this.key };
    const params = {
      type: 'people_for_mention',
      memo_id: this.memoId.toString(),
    };
    this.dropdownService
      .getDropdownWithHeader(header, params)
      .subscribe(
        (dropdown: DropdownResponse) => {
          this.profileList = dropdown.people_for_mention;
        },
        (error: { error: string }) => {
          if (this.translate.currentLang === 'th') {
            this.swal.toastNotification({
              type: 'error',
              content:
                'ไม่สามารถโหลดข้อมูล user สำหรับการกล่าวถึงได้',
            });
          } else {
            this.swal.toastNotification({
              type: 'error',
              content: `Can't get mention users.`,
            });
          }
        },
      );
  }

  uploadFile(fileInput): void {
    if (fileInput.target.files[0].size / (1000 * 1000) >= 30) {
      this.alert.error('กรุณาอัปโหลดไฟล์ขนาดไม่เกิน 30 MB');
      this.fileUpload = null;
      return;
    }
    this.uploaded.emit(fileInput);
    fileInput.target.value = '';
  }

  triggerDebouncer(event): void {
    this.debouncer.next(event);
  }
}
