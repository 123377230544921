import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-image-upload-preview',
  templateUrl: './image-upload-preview.component.html',
  styleUrls: ['./image-upload-preview.component.scss'],
})
export class ImageUploadPreviewComponent {
  file: File;
  preview: any;
  @Input() isUploadable = true;
  @Input() oldImageURL: string;
  @Output() uploaded = new EventEmitter();

  constructor() {}

  uploadImage(fileInput: any) {
    this.file = fileInput.target.files[0];
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (innerFileInput: any) => {
        this.preview = innerFileInput.target.result;
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
    this.uploaded.emit(fileInput.target.files[0]);
  }
}
