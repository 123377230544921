import { Directive, HostListener } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Directive({
  selector: '[appFormSubmitValidation]',
})
export class FormSubmitValidationDirective {
  constructor(private control: ControlContainer) {}
  @HostListener('click', ['$event'])
  handleClickEvent() {
    const form = this.control.formDirective as FormGroupDirective;
    this.markAsTouched(form.form);
  }

  private markAsTouched(formGroup: FormGroup): void {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    Object.values(formGroup.controls).forEach(
      (control: FormArray | FormGroup | FormControl) => {
        control.markAsTouched();
        control.updateValueAndValidity({
          onlySelf: false,
          emitEvent: true,
        });
        if (control instanceof FormGroup && control.controls) {
          this.markAsTouched(control);
        }
      },
    );
  }
}
