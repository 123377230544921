import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss'],
})
export class DeleteConfirmationModalComponent implements OnDestroy {
  modal: NgbModalRef;
  @Input() type = 'icon';
  @Input() header = 'MEMOS.CONFIRM-DELETE';
  @Input() deleteMessage = 'MEMOS.DELETE';
  @Input() text = 'MEMOS.TEXT';
  @Input() useNewDesign = true;

  @Input() data: any;
  @Output() submitted = new EventEmitter();
  themeList: ThemeList;
  themeSubscription: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    private themeService: ThemeService,
  ) {
    this.themeSubscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }
  ngOnDestroy(): void {
    this.themeSubscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  open(content) {
    this.modal = this.modalService.open(content, { centered: true });
  }

  submit() {
    this.submitted.emit({ data: this.data });
    this.modal.close();
  }
}
