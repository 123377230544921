import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { featureFlag } from 'src/environments/environment';

@Directive({
  selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  private requiredFlag: any;
  private reverted = false;

  private isHidden = true;

  @Input() set appFeatureFlag(val: any) {
    if (val) {
      if (val.startsWith('!')) {
        this.reverted = true;
        val = val.replace('!', '');
      }
      if (val.includes('|')) {
        val = val.split('|');
      }
      this.requiredFlag = val;
      this.updateView();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.updateView();
  }

  private updateView(): void {
    if (this.checkValidity()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.viewContainer.clear();
      this.isHidden = true;
    }
  }

  private checkValidity(): boolean {
    if (Array.isArray(this.requiredFlag)) {
      const result = this.requiredFlag.some((flagName) => {
        return featureFlag[flagName];
      });

      return result;
    } else {
      const enabled = featureFlag[this.requiredFlag];
      return (
        this.requiredFlag && (this.reverted ? !enabled : enabled)
      );
    }
  }
}
