import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from './api.constant';
import { ThemeInfo } from '../../modules/setting/component/theme/shared/theme.models';
import { DrfResponse } from '../../modules/profile/shared/profile.models';

export interface ThemeList {
  id: number;
  name: string;
  display_email: string;
  display_pdf: string;
  display_login: string;
  bg_login_color: string;
  bg_login_second_color: string;
  box_login_color: string;
  text_login_color: string;
  text_box_login_color: string;
  display_sidebar: string;
  bg_color_primary: string;
  bg_color_secondary: string;
  text_color: string;
  hovered_text_color: string;
  header_table_color: string;
  text_header_table_color: string;
  active: boolean;
  button_login_color: string;
  text_button_login_color: string;
}
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public currentTheme;
  private style;
  // tslint:disable-next-line:variable-name
  public small_logo = new Subject();
  // tslint:disable-next-line:variable-name
  public long_logo = new Subject();
  public isSidebarOpen = false;
  themeList: ThemeList;
  private setTheme = new BehaviorSubject<ThemeList>(null);
  data = this.setTheme.asObservable();

  constructor(
    private http: ApiService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  public fetchData(): Observable<DrfResponse<ThemeInfo>> {
    return this.http.get(API_URL.theme_color_list);
  }

  public loadTheme(id: number): Observable<ThemeInfo> {
    return this.http.get(API_URL.theme_color_list + id + '/');
  }

  public updateTheme(theme: any, id) {
    return this.http.patch(
      API_URL.theme_color_list + id + '/',
      theme,
    );
  }

  public createTheme(theme: any) {
    return this.http.post(API_URL.theme_color_list, theme);
  }

  public deleteTheme(id) {
    return this.http.delete(API_URL.theme_color_list + id + '/');
  }

  public removeEmailLogo(id) {
    return this.http.delete(
      API_URL.theme_color_list + id + '/logo-email/',
    );
  }

  public getTheme(): Observable<ThemeInfo> {
    return this.http.get(API_URL.theme_active);
  }

  isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 768px)');
  }

  isMobileSmallScreen() {
    return this.breakpointObserver.isMatched('(max-width: 576px)');
  }

  isTabletOrMobile() {
    return this.breakpointObserver.isMatched(['(max-width: 1024px)']);
  }

  isTabletOrMobilePortrait() {
    return this.breakpointObserver.isMatched([
      '(max-width: 1024px)',
      '(orientation: portrait)',
    ]);
  }

  setThemeList(theme: ThemeList) {
    this.themeList = theme;
    this.setTheme.next(this.themeList);
  }

  themeSetting() {
    this.setThemeList(this.themeList);
  }
}
