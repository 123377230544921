import {
  Directive,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[appScrollTracker]',
})
export class ScrollTrackerDirective {
  @Output() scrollingFinished = new EventEmitter<void>();

  @HostListener('scroll', ['$event']) onScroll(event): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 5
    ) {
      this.scrollingFinished.emit();
    }
  }
}
