import { ErrorMessageDetail } from '../../modules/manage-types-upload-memo/shared/manage-types-upload-memo.models';

export enum Language {
  th = 'th',
  en = 'en',
}

export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
}

export function buildErrorHTMLMessage(
  error: ErrorMessageDetail,
  wrap?: boolean,
): string {
  if (Array.isArray(error)) {
    const list = error
      .map((errorItem) => {
        return typeof errorItem === 'string'
          ? `<li>${errorItem}</li>`
          : buildErrorHTMLMessage(errorItem);
      })
      .join('');
    return `<ul class="mb-0 pl-3">${list}</ul>`;
  }

  if (typeof error === 'string') {
    return wrap
      ? `<ul class="mb-0 pl-3"><li>${error}</li></ul>`
      : error;
  }

  return Object.entries(error)
    .map(([key, value]) => {
      return `
        <ul class="mb-0 pl-3">
          <li>${toTitleCase(key)}
            ${buildErrorHTMLMessage(value, true)}
          </li>
        </ul>
      `;
    })
    .join('');
}

export function buildFullName(
  firstname: string,
  lastname: string,
): string {
  if (firstname && lastname) {
    return `${firstname} ${lastname}`;
  } else {
    return firstname || lastname;
  }
}

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 =
    (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (c) => {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) {
        // Use timestamp until depleted
        // tslint:disable-next-line:no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // Use microseconds since page-load if supported
        // tslint:disable-next-line:no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
}
