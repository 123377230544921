import { environment } from '../../../environments/environment';

const baseUrl = environment.baseUrl + '/api';

export const apiUrl = {
  login: baseUrl + '/token-auth/',
  users: baseUrl + '/profiles/',
};

export const ADFS_URL = {
  login: environment.baseUrl + '/api/oauth2/login/',
};
