import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  transform(link: string): string {
    let replacedMessage = null;
    // URLs starting with http://, https://, or ftp://
    const httpPattern = /(\b(http|https|ftp):\/\/(\S*))/gim;
    replacedMessage = link.replace(
      httpPattern,
      '<a href="$1" target="_blank">$1</a>',
    );
    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const basePattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedMessage = replacedMessage.replace(
      basePattern,
      '$1<a href="http://$2" target="_blank">$2</a>',
    );
    // Chang email addresses to mailto:: links
    const emailPattern =
      /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedMessage = replacedMessage.replace(
      emailPattern,
      '<a href="mailto:$1">$1</a>',
    );
    return replacedMessage;
  }
}
