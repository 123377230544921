import { Injectable } from '@angular/core';
import Swal, {
  SweetAlertIcon,
  SweetAlertResult,
} from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private translate: TranslateService) {}

  success(
    title: string = this.translate.instant('MEMOS.SAVE-CHANGES'),
    timer = 8000,
  ): void {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: title || 'Success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: ' <i class="fas fa-times"></i>',
      customClass: {
        cancelButton: 'custom-cancel-alert',
      },
      timer: timer || 8000,
    });
  }

  error(title = 'Error', timer = 8000): void {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: title || 'Error',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: ' <i class="fas fa-times"></i>',
      customClass: {
        cancelButton: 'custom-cancel-alert',
      },
      timer: timer || 8000,
    });
  }

  errorDetail(title: string | HTMLElement | JQuery, html): void {
    Swal.fire({
      icon: 'error',
      title,
      showConfirmButton: true,
      html,
    });
  }

  confirm(
    title: string = this.translate.instant('ALERT.Are you sure'),
    text: string = this.translate.instant(
      'ALERT.You want to save your changes',
    ),
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = this.translate.instant(
      'ALERT.Confirm',
    ),
    showCancelButton = true,
    cancelButtonText: string = this.translate.instant('ALERT.Cancel'),
  ): Promise<
    SweetAlertResult<{
      isConfirmed: boolean;
      isDismissed: boolean;
      value: boolean;
    }>
  > {
    return Swal.fire({
      title,
      text,
      icon,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
    });
  }

  permission(): Promise<SweetAlertResult<unknown>> {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: ' No permission to access!',
    });
  }
}
