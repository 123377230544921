import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DepartmentStateModel } from './department.model';
import { Injectable } from '@angular/core';
import { GetDepartmentDropdownList } from './department.action';

@State<DepartmentStateModel>({
  name: 'departmentDropdownList',
  defaults: {
    dropdownList: null,
    selectedDepartmentIDList: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class DepartmentState {
  constructor() {}

  @Selector()
  static dropdownList(state: DepartmentStateModel) {
    return state;
  }

  @Action(GetDepartmentDropdownList)
  GetDepartmentDropdownList(
    ctx: StateContext<GetDepartmentDropdownList>,
    action: DepartmentStateModel,
  ) {
    ctx.setState({
      dropdownList: action.dropdownList,
      selectedDepartmentIDList: action.selectedDepartmentIDList,
    });
  }
}
