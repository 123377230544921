import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HideLoaderAction, ShowLoaderAction } from './load.actioins';

export class LoaderStateModel {
  public status: boolean;
}

@State<LoaderStateModel>({
  name: 'loader',
  defaults: {
    status: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class LoaderState {
  constructor() {}

  @Selector()
  public static status(state: LoaderStateModel): boolean {
    return state.status;
  }

  @Action(ShowLoaderAction)
  public showLoaderAction(ctx: StateContext<LoaderStateModel>): void {
    ctx.setState({ status: true });
  }

  @Action(HideLoaderAction)
  public hideLoaderAction(ctx: StateContext<LoaderStateModel>): void {
    ctx.setState({ status: false });
  }
}
