import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { apiUrl } from '../http/api.constant';
import { Logout } from '../../store/auth/auth.actions';
import { Store } from '@ngxs/store';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TokenService } from '../authentication/token.service';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/shared/service/title-service';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: Store,
    private tokenService: TokenService,
    private titleService: TitleService,
    private spinner: SpinnerService,
  ) {}

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err?.error === 'WEBSITE_IN_MAINTENANCE_MODE') {
          if (!this.router.url.startsWith('/website-maintenance')) {
            this.router
              .navigate(['/website-maintenance'])
              .then(() => {
                window.location.reload();
              });
          }
          return;
        }

        if (
          err.status === 401 &&
          request.url !== apiUrl.login &&
          !request.url.includes('token-refresh')
        ) {
          const errorCode = err?.error?.code;
          if (errorCode && errorCode === 'concurrent_login') {
            this.spinner.hide();
            Swal.fire({
              title: err.error.title,
              text: err.error.text,
              icon: 'warning',
              width: '550px',
              showCancelButton: false,
              confirmButtonColor: '#3A3A3A',
              confirmButtonText: 'OK',
            }).then(() => {
              this.store.dispatch(Logout);
              this.router.navigate(['/login']);
              return throwError(err);
            });
          } else {
            return this.handle401Error(request, next);
          }
        } else {
          this.spinner.hide();
          return throwError(err);
        }
      }),
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.tokenService.getRefreshToken();
      if (token) {
        return this.tokenService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.tokenService.saveToken(token.access);
            this.refreshTokenSubject.next(token.access);

            return next.handle(
              this.addTokenHeader(request, token.access),
            );
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.spinner.hide();
            if (err?.error === 'WEBSITE_IN_MAINTENANCE_MODE') {
              if (
                !this.router.url.startsWith('/website-maintenance')
              ) {
                this.router
                  .navigate(['/website-maintenance'])
                  .then(() => {
                    window.location.reload();
                  });
              }
              return;
            }

            this.store.dispatch(Logout);
            this.router.navigate(['/login']);
            return throwError(err);
          }),
        );
      } else {
        this.isRefreshing = false;
        this.spinner.hide();
        this.store.dispatch(Logout);
        this.router.navigate(['/login']);
        return;
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) =>
        next.handle(this.addTokenHeader(request, token)),
      ),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
