import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class OtpMailService {
  constructor(private http: ApiService) {}

  send(data?, header?) {
    return this.http.post(`/api/email-otp/send/`, data, header);
  }

  verify(data) {
    return this.http.post(`/api/email-otp/verify/`, data);
  }

  close(data) {
    return this.http.post(`/api/email-otp/close/`, data);
  }
}
