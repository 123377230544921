import {
  Directive,
  Input,
  HostListener,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFormValidation]',
})
export class FormValidationDirective implements OnInit, OnDestroy {
  @Input() validateMsg: string;
  @Input() errorMsg = 'is required ';
  errorSpanId = '';
  statusChangeSubscription: Subscription;
  valueChangeSubscription: Subscription;

  constructor(
    private elRef: ElementRef,
    private control: NgControl,
  ) {}

  ngOnInit(): void {
    this.errorSpanId = this.control.name + '-error-msg';
    this.statusChangeSubscription =
      this.control.statusChanges?.subscribe(() => {
        this.checkValidate();
      });
    this.valueChangeSubscription =
      this.control.valueChanges.subscribe(() => {
        const value = this.control.valueAccessor as NgSelectComponent;
        setTimeout(() => {
          this.checkValidate();
        }, 500);
      });
  }

  ngOnDestroy(): void {
    this.statusChangeSubscription?.unsubscribe();
    this.valueChangeSubscription?.unsubscribe();
  }
  @HostListener('blur', ['$event'])
  handleBlurEvent(event) {
    this.checkValidate();
  }

  private showError() {
    this.removeError();
    const valErrors: ValidationErrors = this.control.errors;
    const firstKey = Object.keys(valErrors)[0];
    const errorMsgKey = this.validateMsg + '-' + firstKey + '-msg';
    let errorMsg = (
      this.validateMsg ? this.validateMsg : this.control.name
    ) as string;
    errorMsg = errorMsg.replace(/\_/g, ' ');
    errorMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
    const errSpan =
      '<small style="color:red;position: absolute;z-index: 2; left: 15px" id="' +
      this.errorSpanId +
      '">' +
      errorMsg +
      ' ' +
      this.errorMsg +
      '</small>';

    this.elRef.nativeElement.parentElement.insertAdjacentHTML(
      'beforeend',
      errSpan,
    );
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) {
      errorElement.remove();
    }
  }

  checkValidate() {
    if (this.control.status === 'INVALID') {
      this.showError();
    } else {
      this.removeError();
    }
  }
}
